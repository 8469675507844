// Types
export type ApiRoutes = {
  BOOKING: {
    BOOTSTRAP_MULTIDAY_RECOMMEND: string;
    IKEA_PHASE: string;
    IKEA_REDIRECT_CREATE: string;
    IN_SERVICE_AREA: string;
    JOB_DRAFT_DATA: string;
    JOB_REPOST_DATA: string;
    MULTIDAY_RECOMMEND: string;
    RABBIT_CATEGORY_PHOTOS: string;
    RABBIT_GRANULAR_AVAILABILITY: string;
    RABBIT_HIRE: string;
    RABBITS: string;
    TASK_TEMPLATE: string;
    TASKER_PROFILE: string;
    UPDATE_JOB_DRAFT: string;
    VALIDATE_PROMO_CODE: string;
  };
  PAYMENTS: {
    BOOTSTRAP: string;
    CREATE_STRIPE_PAYMENT_METHOD: string;
    PAYMENT_INTENT: string;
  };
  PRESS: {
    PRESS_PAGE: string;
    PRESS_RELEASE: string;
    PRESS_RELEASES_LIST: string;
    PRESS_RELEASES_PAGE: string;
  };
  USER: {
    ACCOUNT: string;
    ACCOUNT_TRANSACTIONS: string;
    ACCOUNT_TRANSACTIONS_EXPORT: string;
    ACCOUNT_TRANSACTIONS_EXPORT_STATUS: string;
    CURRENT: string;
    DEACTIVATE: string;
    DEACTIVATION: string;
    GEOLOCATE_IP: string;
    LOGOUT: string;
    NOTIFICATIONS: string;
    PASSWORD: string;
    REDEMPTIONS: string;
    ZENDESK_TOKEN: string;
  };
};

const apiRoutes: ApiRoutes = {
  BOOKING: {
    BOOTSTRAP_MULTIDAY_RECOMMEND:
      '/api/v3/jobs/post/bootstrap_multiday_recommend',
    IKEA_PHASE: '/api/v3/ikea/phase_manager/get_phase.json',
    IKEA_REDIRECT_CREATE: '/api/v3/web-client/job_draft/ikea_redirect_create',
    IN_SERVICE_AREA: '/api/v3/geospatial/in_service_area.json',
    JOB_DRAFT_DATA: '/api/v3/jobs/job_draft_data.json',
    JOB_REPOST_DATA: '/api/v3/jobs/repost_data.json',
    MULTIDAY_RECOMMEND: '/api/v3/jobs/post/multiday_recommend.json',
    RABBIT_CATEGORY_PHOTOS: '/api/v3/rabbit_category_photos',
    RABBIT_GRANULAR_AVAILABILITY:
      '/api/v3/web-client/build/rabbit_granular_availability.json',
    RABBIT_HIRE: '/api/v3/jobs/post/hire.json',
    RABBITS: '/api/v3/rabbits',
    TASK_TEMPLATE: '/api/v3.3/web-client/task_templates',
    TASKER_PROFILE: '/api/v3/web-client/profile',
    UPDATE_JOB_DRAFT: '/api/v3/web-client/job_draft',
    VALIDATE_PROMO_CODE: '/api/v3/promotions/valid.json',
  },
  PAYMENTS: {
    BOOTSTRAP: '/api/v3/web-client/bootstrap.json',
    CREATE_STRIPE_PAYMENT_METHOD: '/api/v3.1/payment_methods.json',
    PAYMENT_INTENT: '/api/v3/payments/intents',
  },
  PRESS: {
    PRESS_PAGE: '/api/press-page',
    PRESS_RELEASE: '/api/press-releases/:slug',
    PRESS_RELEASES_LIST: '/api/press-releases',
    PRESS_RELEASES_PAGE: '/api/press-releases-page',
  },
  USER: {
    ACCOUNT: '/api/v3/account.json',
    ACCOUNT_TRANSACTIONS: '/api/v3/payments/history.json',
    ACCOUNT_TRANSACTIONS_EXPORT: '/api/v3/payments/export.json',
    ACCOUNT_TRANSACTIONS_EXPORT_STATUS: '/api/v3/payments/check_export.json',
    CURRENT: '/current_user',
    DEACTIVATE: '/api/v3/deactivate.json',
    DEACTIVATION: '/api/v3/self_deactivation.json',
    GEOLOCATE_IP: '/geolocate_ip',
    LOGOUT: '/logout',
    NOTIFICATIONS: '/api/v3/account/notifications.json',
    PASSWORD: '/api/v3/password.json',
    REDEMPTIONS: '/api/v3/redeem.json',
    ZENDESK_TOKEN: '/api/v3/zendesk_messaging_token.json',
  },
};

export default apiRoutes;
